
import { defineComponent, ref } from 'vue'
import { HomeOutlined, PlusOutlined, DeleteOutlined, FilterOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  components: {
    HomeOutlined,
    PlusOutlined,
    DeleteOutlined,
    FilterOutlined
  },
  setup () {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        onFilter: (value, record) => record.name.indexOf(value) === 0,
        sorter: (a, b) => a.name.length - b.name.length,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: 'Email',
        dataIndex: 'email',
        onFilter: (value, record) => record.email.indexOf(value) === 0,
        sorter: (a, b) => a.email.length - b.email.length,
        sortDirections: ['descend', 'ascend'],
        scopedSlots: { customRender: 'email' }
      },
      {
        title: 'Mobile No',
        dataIndex: 'mobileNo'
      },
      {
        title: 'Client',
        dataIndex: 'client'
      },
      {
        title: 'Status',
        dataIndex: 'status'
      },
      {
        title: 'Action',
        key: 'action',
        scopedSlots: { customRender: 'action' }
      }
    ]
    const data = [
      {
        key: '1',
        name: 'Nijesh W',
        email: 'nijesh.w.17@gmail.com',
        mobileNo: '9942542676',
        client: 'Logezy',
        status: 'Active'
      },
      {
        key: '2',
        name: 'Rincy Raju',
        email: 'rincy@gmail.com',
        mobileNo: '9942542676',
        client: 'Logezy',
        status: 'Active'
      },
      {
        key: '3',
        name: 'Akhil US',
        email: 'akhil@gmail.com',
        mobileNo: '9942542676',
        client: 'Logezy',
        status: 'Inactive'
      }
    ]
    const searchString = ref<string>('')
    const onSearch = (searchValue: string) => {
      console.log('use value', searchValue)
    }
    return {
      columns,
      data,
      onSearch,
      searchString
    }
  }
})
